<template>
  <div class="">
    <div class="banner text-center py-20 text-white">
      <h1 class="text-4xl font-bold">Bize Ulaşın</h1>
    </div>
    <div
      class="
        flex flex-col
        items-center
        sm:justify-between
        sm:flex-row
        sm:items-start
        contact
        px-3
        sm:px-5
        md:px-10
        lg:px-20
        xl:px-40
      "
    >
      <div class="flex flex-col items-center">
        <div class="text-2xl font-bold mt-10 w-full">
          Bizimle İletişime Geçin
        </div>
        <div class="w-full">
          <form action="">
            <div class="form w-full">
              <div class="form-group flex flex-col sm:flex-row my-5 w-full">
                <div class="name flex flex-col mt-3 w-full mr-5">
                  <label for="name" class="font-semibold">Ad</label>
                  <input
                    type="text"
                    class="
                      w-full
                      h-12
                      px-3
                      border border-gray-100
                      bg-gray-100
                      rounded-full
                      mt-2
                    "
                    v-model="$v.user.name.$model"
                    @click="user.name = ''"
                    :class="{
                      'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                        $v.user.name.$error,
                    }"
                  />
                  <small
                    v-if="$v.user.name.$error == true"
                    class="form-text text-red-500"
                    >Bu alan zorunludur</small
                  >
                </div>
                <div class="surname flex flex-col mt-3 w-full">
                  <label for="surname" class="font-semibold">Soyad</label>
                  <input
                    type="text"
                    class="
                      w-full
                      h-12
                      px-3
                      border border-gray-100
                      bg-gray-100
                      rounded-full
                      mt-2
                    "
                    v-model="$v.user.surname.$model"
                    :class="{
                      'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                        $v.user.surname.$error,
                    }"
                  />
                  <small
                    v-if="$v.user.surname.$error == true"
                    class="form-text text-red-500"
                    >Bu alan zorunludur</small
                  >
                </div>
              </div>
              <div class="form-group flex flex-col sm:flex-row my-5 w-full">
                <div class="email flex flex-col mt-3 w-full mr-5">
                  <label for="email" class="font-semibold"
                    >E-posta adresi</label
                  >
                  <input
                    type="text"
                    class="
                      w-full
                      h-12
                      px-3
                      border border-gray-100
                      bg-gray-100
                      rounded-full
                      mt-2
                    "
                    v-model="$v.user.email.$model"
                    @click="user.email = ''"
                    :class="{
                      'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                        $v.user.email.$error,
                    }"
                  />
                  <small
                    v-if="$v.user.email.required == false"
                    class="form-text text-red-500"
                    >Bu alan zorunludur</small
                  >
                  <small
                    v-if="$v.user.email.$invalid && $v.user.email.$model != ''"
                    class="form-text text-red-500"
                    >Geçerli bir email adresi giriniz</small
                  >
                </div>
                <div class="phone flex flex-col mt-3 w-full">
                  <label for="phone" class="font-semibold"
                    >Telefon Numarası</label
                  >
                  <input
                    type="text"
                    class="
                      w-full
                      h-12
                      px-3
                      border border-gray-100
                      bg-gray-100
                      rounded-full
                      mt-2
                    "
                    v-model="user.phone"
                  />
                </div>
              </div>
              <div class="form-group flex flex-col my-5 w-full">
                <label for="">Açıklama</label>
                <textarea
                  name=""
                  id=""
                  class="
                    w-full
                    rounded-badge
                    border-gray-100
                    bg-gray-100
                    p-3
                    resize-none
                  "
                  rows="5"
                ></textarea>
              </div>
            </div>

            <div class="button text-left mb-10">
              <button
                class="bg-blue-500 px-5 py-2 rounded-full text-white"
                type="submit"
                :disabled="$v.$invalid"
              >
                GÖNDER
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="contact-info my-10 ml-10">
        <div class="text-2xl font-bold mb-6">İletişim Bilgileri</div>

        <div class="flex">
          <div class="mr-2">
            <i class="fas fa-map-marker-alt text-gray-400 text-xl"></i>
          </div>
          <div class="">
            <span class="font-bold"> Adres </span>
            <p>Kardeşler Mahallesi 51-52. Sokak No: 4A</p>
          </div>
        </div>
        <div class="flex my-6">
          <div class="mr-2">
            <i class="fas fa-phone-alt text-gray-400 text-xl"></i>
          </div>
          <div class="">
            <span class="font-bold"> Telefon </span>
            <p>0 (346) 502 3624</p>
          </div>
        </div>
        <div class="flex">
          <div class="mr-2">
            <i class="fas fa-envelope text-gray-400 text-xl"></i>
          </div>
          <div class="">
            <span class="font-bold"> Email</span>
            <p>info@detaysoft.com</p>
          </div>
        </div>
      </div>
    </div>
    <Map />
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Map from "../components/Map.vue";

export default {
  components: {
    Map,
  },
  data() {
    return {
      user: {
        name: " ",
        surname: " ",
        email: "email@email.com",
        phoneNumber: "",
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  validations() {
    return {
      user: {
        name: { required },
        surname: { required },
        email: { required, email },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-image: url(../img/slider1.png);
}
input:focus,
textarea:focus {
  outline: none;
}
.map {
  width: 100%;
  max-height: 300px;
}
</style>