var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_vm._m(0),_c('div',{staticClass:"\n      flex flex-col\n      items-center\n      sm:justify-between\n      sm:flex-row\n      sm:items-start\n      contact\n      px-3\n      sm:px-5\n      md:px-10\n      lg:px-20\n      xl:px-40\n    "},[_c('div',{staticClass:"flex flex-col items-center"},[_c('div',{staticClass:"text-2xl font-bold mt-10 w-full"},[_vm._v(" Bizimle İletişime Geçin ")]),_c('div',{staticClass:"w-full"},[_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form w-full"},[_c('div',{staticClass:"form-group flex flex-col sm:flex-row my-5 w-full"},[_c('div',{staticClass:"name flex flex-col mt-3 w-full mr-5"},[_c('label',{staticClass:"font-semibold",attrs:{"for":"name"}},[_vm._v("Ad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.user.name.$model),expression:"$v.user.name.$model"}],staticClass:"\n                    w-full\n                    h-12\n                    px-3\n                    border border-gray-100\n                    bg-gray-100\n                    rounded-full\n                    mt-2\n                  ",class:{
                    'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                      _vm.$v.user.name.$error,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.$v.user.name.$model)},on:{"click":function($event){_vm.user.name = ''},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.name, "$model", $event.target.value)}}}),(_vm.$v.user.name.$error == true)?_c('small',{staticClass:"form-text text-red-500"},[_vm._v("Bu alan zorunludur")]):_vm._e()]),_c('div',{staticClass:"surname flex flex-col mt-3 w-full"},[_c('label',{staticClass:"font-semibold",attrs:{"for":"surname"}},[_vm._v("Soyad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.user.surname.$model),expression:"$v.user.surname.$model"}],staticClass:"\n                    w-full\n                    h-12\n                    px-3\n                    border border-gray-100\n                    bg-gray-100\n                    rounded-full\n                    mt-2\n                  ",class:{
                    'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                      _vm.$v.user.surname.$error,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.$v.user.surname.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.surname, "$model", $event.target.value)}}}),(_vm.$v.user.surname.$error == true)?_c('small',{staticClass:"form-text text-red-500"},[_vm._v("Bu alan zorunludur")]):_vm._e()])]),_c('div',{staticClass:"form-group flex flex-col sm:flex-row my-5 w-full"},[_c('div',{staticClass:"email flex flex-col mt-3 w-full mr-5"},[_c('label',{staticClass:"font-semibold",attrs:{"for":"email"}},[_vm._v("E-posta adresi")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.user.email.$model),expression:"$v.user.email.$model"}],staticClass:"\n                    w-full\n                    h-12\n                    px-3\n                    border border-gray-100\n                    bg-gray-100\n                    rounded-full\n                    mt-2\n                  ",class:{
                    'border-2 border-red-500 focus:ring-1 focus:ring-red-500':
                      _vm.$v.user.email.$error,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.$v.user.email.$model)},on:{"click":function($event){_vm.user.email = ''},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.email, "$model", $event.target.value)}}}),(_vm.$v.user.email.required == false)?_c('small',{staticClass:"form-text text-red-500"},[_vm._v("Bu alan zorunludur")]):_vm._e(),(_vm.$v.user.email.$invalid && _vm.$v.user.email.$model != '')?_c('small',{staticClass:"form-text text-red-500"},[_vm._v("Geçerli bir email adresi giriniz")]):_vm._e()]),_c('div',{staticClass:"phone flex flex-col mt-3 w-full"},[_c('label',{staticClass:"font-semibold",attrs:{"for":"phone"}},[_vm._v("Telefon Numarası")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.phone),expression:"user.phone"}],staticClass:"\n                    w-full\n                    h-12\n                    px-3\n                    border border-gray-100\n                    bg-gray-100\n                    rounded-full\n                    mt-2\n                  ",attrs:{"type":"text"},domProps:{"value":(_vm.user.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "phone", $event.target.value)}}})])]),_vm._m(1)]),_c('div',{staticClass:"button text-left mb-10"},[_c('button',{staticClass:"bg-blue-500 px-5 py-2 rounded-full text-white",attrs:{"type":"submit","disabled":_vm.$v.$invalid}},[_vm._v(" GÖNDER ")])])])])]),_vm._m(2)]),_c('Map')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner text-center py-20 text-white"},[_c('h1',{staticClass:"text-4xl font-bold"},[_vm._v("Bize Ulaşın")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group flex flex-col my-5 w-full"},[_c('label',{attrs:{"for":""}},[_vm._v("Açıklama")]),_c('textarea',{staticClass:"\n                  w-full\n                  rounded-badge\n                  border-gray-100\n                  bg-gray-100\n                  p-3\n                  resize-none\n                ",attrs:{"name":"","id":"","rows":"5"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-info my-10 ml-10"},[_c('div',{staticClass:"text-2xl font-bold mb-6"},[_vm._v("İletişim Bilgileri")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-2"},[_c('i',{staticClass:"fas fa-map-marker-alt text-gray-400 text-xl"})]),_c('div',{},[_c('span',{staticClass:"font-bold"},[_vm._v(" Adres ")]),_c('p',[_vm._v("Kardeşler Mahallesi 51-52. Sokak No: 4A")])])]),_c('div',{staticClass:"flex my-6"},[_c('div',{staticClass:"mr-2"},[_c('i',{staticClass:"fas fa-phone-alt text-gray-400 text-xl"})]),_c('div',{},[_c('span',{staticClass:"font-bold"},[_vm._v(" Telefon ")]),_c('p',[_vm._v("0 (346) 502 3624")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-2"},[_c('i',{staticClass:"fas fa-envelope text-gray-400 text-xl"})]),_c('div',{},[_c('span',{staticClass:"font-bold"},[_vm._v(" Email")]),_c('p',[_vm._v("info@detaysoft.com")])])])])}]

export { render, staticRenderFns }